import { graphql, useStaticQuery } from 'gatsby';

const UseHomeEvents = () => {
  const data = useStaticQuery(graphql`
    query EventsQuery {
      allMedcoeEventAncmtJson(filter: { Portlet_ID: { eq: 17 } }) {
        nodes {
          id
          Event
          Event_Date
          Event_Date_End
        }
      }
    }
  `);

  return data.allMedcoeEventAncmtJson.nodes.map(event => ({
    id: event.id,
    name: event.Event,
    startDate: event.Event_Date,
    endDate: event.Event_Date_End
  }));
};

export default UseHomeEvents;
