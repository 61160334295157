import { graphql, useStaticQuery } from 'gatsby';

const UseHomeNews = () => {
  const data = useStaticQuery(graphql`
    query NewsQuery {
      allMedcoeEventAncmtJson(
        filter: { Portlet_ID: { eq: 36 } }
        sort: { fields: Event_Date, order: DESC }
        limit: 8
      ) {
        nodes {
          id
          Event
        }
      }
    }
  `);

  return data.allMedcoeEventAncmtJson.nodes.map(event => ({
    id: event.id,
    name: event.Event
  }));
};

export default UseHomeNews;
