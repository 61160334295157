import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Banner from './banner';
import Navigation from './navigation';
import Footer from './footer';

const HomePageLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query HomeSiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Banner />
      <Navigation siteTitle={data.site.siteMetadata.title} />
      <a className='usa-skipnav' href='#main-content'>
        Skip to main content
      </a>

      <main id='main-content'>{children}</main>

      <Footer siteTitle={data.site.siteMetadata.title} />
    </>
  );
};

HomePageLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default HomePageLayout;
