import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const StyledHeroSection = styled.section`
  background-color: ${(props) => props.bgColor || null};
  color: ${(props) => props.textColor || null};
`;

const HeroSection = ({ title, bgColor, textColor, children }) => (
  <StyledHeroSection
    className='padding-top-6 padding-bottom-6'
    bgColor={bgColor}
    textColor={textColor}
  >
    <div className='grid-container'>
      <div className='grid-row grid-gap-lg'>
        <div className='tablet:grid-col-5'>
          <h2 className='font-heading-xl'>{title}</h2>
          {title === 'News' && (
            <a
              href='https://www.usnews.com/best-graduate-schools/top-health-schools/u-s-army--73'
              target='_blank'
              rel='noopener'
            >
              <StaticImage
                src='../../images/best-grad-school.svg'
                alt='best graduate school winner'
                width={200}
              />
            </a>
          )}
                    {title === 'Council on Occupational Education' && (
            <a
              href='https://council.org/'
              target='_blank'
              rel='noopener'
            >
              <StaticImage
                src='../../images/COE.PNG'
                alt='Council on Occupational Education'
                width={200}
              />
            </a>
          )}
        </div>
        <div className='tablet:grid-col-7'>{children}</div>
      </div>
    </div>
  </StyledHeroSection>
);

HeroSection.propTypes = {
  title: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default HeroSection;
