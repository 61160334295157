import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './HomeCarousel.css'

export default class PreviousNextMethods extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render(){
    const settings = {
      infinite: true,
      speed: 100,
      slidesToShow: 1,
      slidesToScroll: 1,
      accessibility: true,
      adaptiveHeight: true,
      autoplay: true,
    };
    return (
      <div>
        <Slider ref={c => (this.slider = c)} {...settings}>
         {/* <div class="container"  key={1}>
            <img src="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/MissionThanksgivingWeb.jpg" />
              <button class="btn">
              <h3>MEDCoE</h3>
              <h3 style={{padding:"0%", color: "PaleGoldenRod"}}>Mission Thanksgiving</h3>
              <p>Welcome soldiers to share in your Thanksgiving meal</p>
              <a href="/mission-thanksgiving">Learn More</a>
            </button>
          </div> */}
          <div class="container"  key={2}>
            <img src="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/medevac-1.jpg" />
              <button class="btn">
              <h3>MEDCoE</h3>
              <h3 style={{padding:"0%", color: "PaleGoldenRod"}}>Warriors Wanted</h3>
              <p>Find out if you have what it takes to join the Army</p>
              <a href="https://www.goarmy.com/">Learn More</a>
            </button>
          </div>
          <div class="container" key={3}>
          <img src="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/endless.jpg" />
          <button class="btn">
          <h3>MEDCoE</h3>
            <h3 style={{padding:  "0%", color: "PaleGoldenRod"}}>Endless Possibilities</h3>
            <p>Nearly 30,000 instructed in over 360 training and education programs</p>
            <a href="https://recruiting.army.mil/mrb/">Learn More</a>
            </button>
          </div>
          <div class="container" key={4}>
          <img src="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/grad.jpg" />
          <button class="btn">
          <h3>MEDCoE</h3>
            <h3 style={{color: "PaleGoldenRod"}}>Graduations</h3>
            <p>Policies and tips to keep Soldiers & guests safe during open graduations</p>
            <a href="https://medcoe.army.mil/graduations">Learn More</a>
            </button>
          </div>
          <div class="container" key={5}>
          <img src="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/learntechbanner.jpg" />
          <button class="btn">
          <h3>MEDCoE</h3>
            <h3 style={{color: "PaleGoldenRod"}}>Are You Ready to Learn Online</h3>
            <p>Tools, Tips, and Advice, to Help You Quickly Transition to Online Teaching and Learning</p>
            <a href="https://medcoe.army.mil/learn-tech">Learn More</a>
            </button>
          </div>
          <div class="container" key={6}>
          <img src="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/medcon2020.jpg" />
          <button class="btn">
          <h3>MEDCoE</h3>
            <h3 style={{color: "PaleGoldenRod"}}>Army Medicine Vision</h3>
            <p>Army Medicine of 2028 is ready, reformed, reorganized, responsive, and relevant</p>
            <a href="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/dbimages/am-vision.pdf">Learn More</a>
            </button>
          </div>
          <div class="container" key={7}>
          <img src="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/flag.jpg" />
          <button class="btn">
          <h3>MEDCoE</h3>
            <h3 style={{color: "PaleGoldenRod"}}>Army Medicine Starts Here!</h3>
            <p>We Develop Leaders and Drive Change</p>
            <a href="https://medcoe.army.mil/about-us">Learn More</a>
            </button>
          </div>
          <div class="container" key={8}>
          <img src="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/21st-century.jpg" />
          <button class="btn">
          <h3>MEDCoE</h3>
            <h3 style={{color: "PaleGoldenRod"}}>21st Century Institution</h3>
            <p>Largest Civilian-Accredited Service School with Programs Consistently Top 10 Ranked Nationally</p>
            <a href="https://medcoe.army.mil/about-us#organizations">Learn More</a>
            </button>
          </div>
          <div class="container" key={9}>
          <img src="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/medevac-2.jpg" />
          <button class="btn">
          <h3>MEDCoE</h3>
            <h3 style={{color: "PaleGoldenRod"}}>Modern and Ready</h3>
            <p>Increasing the Army's Lethality through Soldier Survivability</p>
            <a href="https://medcoe.army.mil/about-us#mission">Learn More</a>
            </button>
          </div>
          <div class="container" key={10}>
          <img src="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/acft.jpg" />
          <button class="btn">
          <h3>MEDCoE</h3>
            <h3 style={{color: "PaleGoldenRod"}}>ACFT</h3>
            <p>The Army Combat Fitness Test connects fitness with combat readiness for all Soldiers</p>
            <a href="https://www.army.mil/acft/">Learn More</a>
            </button>
          </div>
          <div class="container" key={11}>
          <img src="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/cop.png" />
          <button class="btn">
          <h3>MEDCoE</h3>
            <h3 style={{color: "PaleGoldenRod"}}>Medical Common Operating Picture</h3>
            <p>Click the link to see how MEDCoE is transforming Army Medicine and setting a new standard in medical excellence</p>
            <a href="https://medssr.tradoc.army.mil/cac-docs/cop/MEDCoECOPEditionTwo30OCT24.pdf">Learn More</a>
            </button>
          </div>
        </Slider>
        <div style={{textAlign: "center"}}>         
		      <ul class="usa-button-group usa-button-group--segmented">
            <li style={{marginLeft: '45%'}}>
              <button className="button" onClick={this.previous}>&#x276C;</button>  
            </li>
            <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
            <li style={{marginRight: '45%'}}>
              <button className="button" onClick={this.next}>&#x276D;</button>   
              </li>
            </ul>
	        </div>
      </div>
    );
  }
}